
import { Link, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const routes = () => {
    return(
    <Routes>
        <Route path={`/sign-in`} element={<SignIn />} />
        <Route path={`/sign-up`} element={<SignUp />} />
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
    </Routes>
    )
}
export default routes;