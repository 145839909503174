import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth-duck";


export const rootReducer = combineReducers({
    auth: auth.reducer,
});

export function* rootSaga() {
    yield all([
        auth.saga(),
        // company.saga(),
        // companies.saga(),
        // contract.saga(),
        // contracts.saga(),
        // projects.saga(),
        // project.saga(),
        // application.saga(),
        // preferences.saga(),
        // templates.saga()
    ]);
}
