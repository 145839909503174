import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import * as authDuck from '../../store/ducks/auth-duck';
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';

export default function SignIn() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [session, setSession] = useState(null);
  const [otp, setOTP] = useState("");
  // const [password, setPassword] = useState("");
  const dispatch = useAppDispatch()

  const executeGetOTP = async (event) => {
    try {
      event.preventDefault();
      const result = await Auth.signIn(username);
      setSession(result);
      console.log("getOTP result", result)
      toast("OTP sent",{ type:"success" });
      // dispatch(authDuck.actions.signIn(result))
    } catch (err) {
      toast(err.toString(),{ type:"error" });
      console.log("cookie storage:" + process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN)
      console.log(err)
    }
  };

  const executeOTPVerification = async (event) => {
    try {
      event.preventDefault();
      const result = await Auth.sendCustomChallengeAnswer(session, otp);
      toast("Login successful",{ type:"success" });
      console.log("Verify OTP result", result)
      dispatch(authDuck.actions.signIn(result))
    } catch (err) {
      toast(err.toString(),{ type:"error" });
      console.log("cookie storage:" + process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN)
      console.log(err)
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate({ pathname: "/admin/default" });
    }
  }, [isAuthenticated])
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="Your Email Address"
          id="email"
          type="text"
          disabled = {!!session}
          onChange={(e) => setUsername(e.target.value)}
        />

        {/* Password */}
        {/* <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        /> */}
        {/* Checkbox */}

        <button onClick={executeGetOTP} disabled={!!session} 
        className={`linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] ${!!session?"":"text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"}`}>
          Get OTP
        </button>
        <InputField
          variant="auth"
          extra="mb-3"
          label="OTP*"
          placeholder="6 DIGIT OTP"
          id="otp"
          type="text"
          onChange={(e) => setOTP(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
        </div>
        <button onClick={executeOTPVerification} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Login
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            onClick={() => { navigate({ pathname: "/auth/sign-up" }); }}
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
}
