import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from 'react-redux';
import "./index.css";
import store from './store/store';
import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "./config/auth";
import App from "./App";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

Amplify.configure({ Auth: AwsConfigAuth });



const root = ReactDOM.createRoot(
  document.getElementById("root")as HTMLElement
  );

root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </ReduxProvider>
);
